import React, { useEffect, useState } from 'react';
import Axios from 'axios';

// Component
import '../../../assets/css/home.css';
import Header from 'components/Header/Header';
import Footer from 'components/Footer/Footer';
import Sidebar from 'components/Sidebar/Sidebar';
import Logout from 'components/Modal/Logout';

// Utils
import { createAlert } from 'utils/MainUtils';

function Home() {
  const [countUser, setCountUser] = useState(0);
  const [totalIncome, setTotalIncome] = useState(0);
  const [countReqChangePass, setCountReqChangePass] = useState(0);
  const [countMan, setCountMan] = useState(0);
  const [countWoman, setCountWoman] = useState(0);
  const [countLadyboy, setCountLadyboy] = useState(0);
  const [countTransgender, setCountTransgender] = useState(0);

  useEffect(() => {
    initialize();
  }, []);

  const initialize = async () => {
    try {
      const headers = { headers: { key: process.env.REACT_APP_ANALYTICS_KEY } };
      const [resultCountUser, resultTotalIncome, resultCountRePass] = await Promise.all([
        Axios.get(`${process.env.REACT_APP_API_URL}/api/count-user`, headers),
        Axios.get(`${process.env.REACT_APP_API_URL}/api/count-purchase-amt`, headers),
        Axios.get(`${process.env.REACT_APP_API_URL}/api/count-req-password`, headers)
      ]);

      setCountUser(resultCountUser.data);
      setTotalIncome(resultTotalIncome.data);
      setCountReqChangePass(resultCountRePass.data);

      const genders = ['M', 'F', 'LB', 'TG'];
      const genderCounts = await Promise.all(
        genders.map(gender =>
          Axios.post(
            `${process.env.REACT_APP_API_URL}/api/count-gender`,
            { gender },
            headers
          )
        )
      );

      const counts = genderCounts.map(response => response.data.userCount);
      const totalCount = counts.reduce((acc, count) => acc + count, 0);

      const percentages = counts.map(count => Math.round((count / totalCount) * 100));

      setCountMan(percentages[0]);
      setCountWoman(percentages[1]);
      setCountLadyboy(percentages[2]);
      setCountTransgender(percentages[3]);

    } catch (error) {
      createAlert('FAILED', error.message);
    }
  };

  return (
    <div className="Home">
      <div id="wrapper">
        <Sidebar />
        <div id="content-wrapper" className="d-flex flex-column">
          <div id="content">
            <Header />
            <div className="container-fluid">
              <div className="row">
                {/* Earnings (Monthly) Card Example */}
                <div className="col-xl-3 col-md-6 mb-3">
                  <div className="card border-left-primary shadow h-100 py-2">
                    <div className="card-body">
                      <div className="row no-gutters align-items-center">
                        <div className="col mr-2" style={{ padding: '1rem' }}>
                          <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                            Total User</div>
                          <div className="h5 mb-0 font-weight-bold text-gray-800">{countUser} People</div>
                        </div>
                        <div className="col-auto">
                          <i className="fas fa-calendar fa-2x text-gray-300" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Earnings (Monthly) Card Example */}
                <div className="col-xl-3 col-md-6 mb-3">
                  <div className="card border-left-success shadow h-100 py-2">
                    <div className="card-body">
                      <div className="row no-gutters align-items-center">
                        <div className="col mr-2" style={{ padding: '1rem' }}>
                          <div className="text-xs font-weight-bold text-success text-uppercase mb-1">
                            Package purchase amount</div>
                          <div className="h5 mb-0 font-weight-bold text-gray-800">{totalIncome} ฿</div>
                        </div>
                        <div className="col-auto">
                          <i className="fas fa-dollar-sign fa-2x text-gray-300" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Pending Requests Card Example */}
                <div className="col-xl-3 col-md-6 mb-3">
                  <div className="card border-left-warning shadow h-100 py-2">
                    <div className="card-body">
                      <div className="row no-gutters align-items-center">
                        <div className="col mr-2" style={{ padding: '1rem' }}>
                          <div className="text-xs font-weight-bold text-warning text-uppercase mb-1">
                            Requests Change Password</div>
                          <div className="h5 mb-0 font-weight-bold text-gray-800">{countReqChangePass}</div>
                        </div>
                        <div className="col-auto">
                          <i className="fas fa-comments fa-2x text-gray-300" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* Content Row */}
              <div className="row">
                {/* Content Column */}
                <div className="col-lg-6 mb-3">
                  {/* Project Card Example */}
                  <div className="card shadow mb-3">
                    <div className="card-header py-3">
                      <h6 className="m-0 font-weight-bold text-primary">Classify users</h6>
                    </div>
                    <div className="card-body">
                      <h4 className="small font-weight-bold">Man<span className="float-right">{countMan}%</span></h4>
                      <div className="progress mb-3">
                        <div className="progress-bar" role="progressbar" style={{ width: `${countMan}%` }} aria-valuenow={countMan} aria-valuemin={0} aria-valuemax={100} />
                      </div>
                      <h4 className="small font-weight-bold">Women<span className="float-right">{countWoman}%</span></h4>
                      <div className="progress mb-3">
                        <div className="progress-bar bg-danger" role="progressbar" style={{ width: `${countWoman}%` }} aria-valuenow={countWoman} aria-valuemin={0} aria-valuemax={100} />
                      </div>
                      <h4 className="small font-weight-bold">Ladyboy<span className="float-right">{countLadyboy}%</span></h4>
                      <div className="progress mb-3">
                        <div className="progress-bar bg-warning" role="progressbar" style={{ width: `${countLadyboy}%` }} aria-valuenow={countLadyboy} aria-valuemin={0} aria-valuemax={100} />
                      </div>
                      <h4 className="small font-weight-bold">Transgender<span className="float-right">{countTransgender}%</span></h4>
                      <div className="progress">
                        <div className="progress-bar bg-success" role="progressbar" style={{ width: `${countTransgender}%` }} aria-valuenow={countTransgender} aria-valuemin={0} aria-valuemax={100} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
      <Logout />
    </div>
  );
}

export default Home;