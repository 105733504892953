import React, { useEffect, useState } from 'react';
import { getUserSys, getValue2Text, getValue3TextByActiveFlag, createAlert } from 'utils/MainUtils';

function PreviewsToggle({ id, button, rf }) {
    const [refList, setRefList] = useState([]);

    useEffect(() => {
        const fetchDataList = async () => {
            try {
                const userId = id;
                const rfSubTable = rf;
                const response = await getUserSys(rfSubTable, userId);

                // ใช้ map เพื่อสร้าง promises ตามเงื่อนไขของ rfSubTable
                const promises = response.map(item => {
                    if (rfSubTable === 'LANG_IMPORTANT' || rfSubTable === 'LEARN_TH') {
                        return getValue3TextByActiveFlag('ACTIVE_FLAG', item.ref_code);
                    } else if (rfSubTable === 'GENDER_FOR') {
                        return getValue2Text('GENDER', item.ref_code);
                    } else {
                        return getValue2Text(item.ref_table_code, item.ref_code);
                    }
                });

                const results = await Promise.all(promises);
                setRefList(results);
            } catch (error) {
                createAlert("FAILED", error.message);
            }
        };

        fetchDataList();
    }, [id, rf]);

    return (
        <div className="option-toggle-buttons d-flex flex-wrap">
            {refList.length === 0 ? (
                <p id="p-detail">-</p>
            ) : (
                refList.map((item, index) => (
                    button ? (
                        <button
                            key={index}
                            className="btn btn-outline-danger"
                            type="button"
                        >
                            {item}
                        </button>
                    ) : (
                        <ul key={index} id="p-detail">
                            <li>{item}</li>
                        </ul>
                    )
                ))
            )}
        </div>
    );
}

export default PreviewsToggle;
