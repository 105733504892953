// Dependency
import React, { useEffect, useState } from 'react';
import Axios from 'axios';

// Component
import '../../../assets/css/package.css';
import Header from 'components/Header/Header';
import Footer from 'components/Footer/Footer';
import Sidebar from 'components/Sidebar/Sidebar';
import Logout from 'components/Modal/Logout';

// Utils
import { createAlert } from 'utils/MainUtils';

function Package() {
  const [mode, setMode] = useState('S');
  const [packageList, setPackageList] = useState([]);
  const [selectedPackageId, setSelectedPackageId] = useState(null);
  const [periodUnitRf, setPeriodUnitRf] = useState([]);

  // Model
  const [name, setName] = useState('');
  const [detail, setDetail] = useState('');
  const [price, setPrice] = useState('');
  const [periodTime, setPeriodTime] = useState('');
  const [periodUnit, setPeriodUnit] = useState('D');

  const initialize = async () => {
    try {
      clearValue();

      const results = await Axios.get(
        `${process.env.REACT_APP_API_URL}/api/get-package`,
        {
          headers: { key: process.env.REACT_APP_ANALYTICS_KEY },
        }
      );

      if (Array.isArray(results.data)) {
        setPackageList(results.data);
      } else {
        setPackageList([]);
      }

      const refRes = await Axios.get(`${process.env.REACT_APP_API_URL}/api/load-rf/PERIOD_UNIT`, {
        headers: { key: process.env.REACT_APP_ANALYTICS_KEY }
      });

      if (refRes.data.status === 'OK') {
        setPeriodUnitRf(refRes.data.result);
      } else {
        createAlert("FAILED", refRes.data.message);
      }
    } catch (error) {
      createAlert('FAILED', error.message);
    }
  };

  useEffect(() => {
    initialize();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      let response;

      if (mode === 'I') {
        const prepareData = {
          name: name,
          detail: detail,
          price: price,
          period_time: periodTime,
          period_unit: periodUnit,
          active_flag: 'Y',
          create_user: localStorage.getItem('username'),
          create_process: window.location.pathname,
        };

        response = await Axios.post(
          `${process.env.REACT_APP_API_URL}/api/create-package`,
          prepareData,
          {
            headers: { key: process.env.REACT_APP_ANALYTICS_KEY },
          }
        );
      } else if (mode === 'U' && selectedPackageId) {
        const prepareData = {
          name: name,
          detail: detail,
          price: price,
          period_time: periodTime,
          period_unit: periodUnit,
          active_flag: 'Y',
          update_user: localStorage.getItem('username'),
          update_process: window.location.pathname,
        };

        response = await Axios.put(
          `${process.env.REACT_APP_API_URL}/api/update-package/${selectedPackageId}`,
          prepareData,
          {
            headers: { key: process.env.REACT_APP_ANALYTICS_KEY },
          }
        );
      }

      if (response && response.data.status === 'OK') {
        handleChangeMode('S');
        createAlert(response.data.status, response.data.message);
        initialize();
      }
    } catch (error) {
      createAlert('FAILED', error.message);
    }
  };

  const handleEdit = (item) => {
    handleChangeMode('U');
    setSelectedPackageId(item.package_id);
    setName(item.name);
    setDetail(item.detail);
    setPrice(item.price);
    setPeriodTime(item.period_time);
    setPeriodUnit(item.period_unit);
  };

  const handleDelete = async (packageId) => {
    try {
      const response = await Axios.delete(
        `${process.env.REACT_APP_API_URL}/api/delete-package/${packageId}`,
        {
          headers: { key: process.env.REACT_APP_ANALYTICS_KEY },
        }
      );

      if (response.data.status === 'OK') {
        createAlert(response.data.status, response.data.message);
        initialize();
      }
    } catch (error) {
      createAlert('FAILED', error.message);
    }
  };

  const handleChangeMode = (mode) => {
    clearValue();
    setMode(mode)
  };

  const clearValue = () => {
    setName('');
    setDetail('');
    setPrice('');
    setPeriodTime('');
    setPeriodUnit('D');
  };

  return (
    <div className="Package">
      <div id="wrapper">
        <Sidebar />
        <div id="content-wrapper" className="d-flex flex-column">
          <div id="content">
            <Header />
            <div className="container-fluid">
              {mode === 'S' && (
                <>
                  <div className="d-flex align-items-center justify-content-between mb-4">
                    <h1 className="h3 mb-0 text-gray-800">Package</h1>
                    <button onClick={() => handleChangeMode('I')} className="btn btn-success">
                      Add Package
                    </button>
                  </div>
                  <div className="card shadow mb-4">
                    <div className="card-header py-3">
                      <h6 className="m-0 font-weight-bold text-primary">Package List</h6>
                    </div>
                    <div className="card-body">
                      <div className="table-responsive">
                        <table className="table table-bordered" id="dataTable" width="100%" cellSpacing="0">
                          <thead>
                            <tr>
                              <th scope="col">#</th>
                              <th scope="col">Name</th>
                              <th scope="col">Detail</th>
                              <th scope="col">Price</th>
                              <th scope="col">Time</th>
                              <th scope="col">Unit</th>
                              <th scope="col">Actions</th>
                            </tr>
                          </thead>
                          <tbody>
                            {Array.isArray(packageList) && packageList.length > 0 ? (
                              packageList.map((item, index) => (
                                <tr key={item.package_id}>
                                  <td>{index + 1}</td>
                                  <td>{item.name}</td>
                                  <td>{item.detail}</td>
                                  <td>{item.price}</td>
                                  <td>{item.period_time}</td>
                                  <td>
                                    {item.period_unit === 'D' && 'DAY'}
                                    {item.period_unit === 'M' && 'MONTH'}
                                    {item.period_unit === 'Y' && 'YEAR'}
                                  </td>
                                  <td>
                                    <button className="btn btn-primary btn-sm" onClick={() => handleEdit(item)} style={{ marginRight: '10px' }}>
                                      <i className="fas fa-edit"></i>
                                    </button>
                                    <button className="btn btn-danger btn-sm" onClick={() => handleDelete(item.package_id)}>
                                      <i className="fas fa-trash"></i>
                                    </button>
                                  </td>
                                </tr>
                              ))
                            ) : (
                              <tr>
                                <td colSpan="7">
                                  <center>
                                    <h2>No Data</h2>
                                  </center>
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </>
              )}

              {(mode === 'I' || mode === 'U') && (
                <>
                  <div className="d-flex align-items-center justify-content-between mb-4">
                    <h1 className="h3 mb-0 text-gray-800">{mode === 'I' ? "Add Package Form" : "Update Package Form"}</h1>
                  </div>
                  <form onSubmit={handleSubmit}>
                    <div className="card shadow mb-4">
                      <div className="card-body">
                        <div className="mb-3">
                          <label className="form-label">Name</label>
                          <input type="text" className="form-control" value={name} required onChange={(e) => setName(e.target.value)} />
                        </div>
                        <div className="mb-3">
                          <label className="form-label">Detail</label>
                          <textarea className="form-control" rows="3" value={detail} onChange={(e) => setDetail(e.target.value)} />
                        </div>
                        <div className="mb-3">
                          <label className="form-label">Price</label>
                          <input type="number" className="form-control" value={price} required onChange={(e) => setPrice(e.target.value)} />
                        </div>
                        <div className="mb-3">
                          <label className="form-label">Period Time</label>
                          <input type="number" className="form-control" value={periodTime} required onChange={(e) => setPeriodTime(e.target.value)} />
                        </div>
                        <div className="mb-3">
                          <label className="form-label">Period Unit</label>
                          <select className="form-select" value={periodUnit} onChange={(e) => setPeriodUnit(e.target.value)}>
                            {periodUnitRf.map((item) => (
                              <option key={item.REFERENCE_CODE} value={item.REFERENCE_CODE}>
                                {item.REFERENCE_VALUE_2_TEXT}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className="d-flex justify-content-between">
                          <button onClick={() => handleChangeMode('S')} type="button" className="btn btn-danger">
                            Cancel
                          </button>
                          <button type="submit" className="btn btn-success">
                            {mode === 'I' ? "Save" : "Update"}
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </>
              )}
            </div>
          </div>
          <Footer />
        </div>
      </div>
      <Logout />
    </div>
  );
}

export default Package;