import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route, Navigate, useLocation, useNavigate } from 'react-router-dom';

// Pages
import Home from 'views/pages/Home/Home';
import Login from 'views/pages/Login/Login';
import NotFoundPage from 'views/pages/Error/NotFoundPage';

import Package from 'views/pages/Package/Package';
import UserManage from 'views/pages/UserManage/UserManage';
import UserProfile from 'views/pages/UserManage/UserProfile';
import PurchaseOrder from 'views/pages/PurchaseOrder/PurchaseOrder';
import AdminManage from 'views/pages/AdminManage/AdminManage';
import Setting from 'views/pages/Setting/Setting';

const UserProfileWrapper = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);
  const id = searchParams.get('id');

  React.useEffect(() => {
    if (!id) {
      navigate('/user-manage');
    }
  }, [id, navigate]);

  return id ? <UserProfile /> : null;
};

const root = ReactDOM.createRoot(document.getElementById('root'));
const isLoggedIn = localStorage.getItem('a_token');

root.render(
  <BrowserRouter>
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="*" element={<NotFoundPage />} />
      <Route path="/" element={isLoggedIn ? <Home /> : <Navigate to="/login" />} />
      <Route path="/home" element={isLoggedIn ? <Home /> : <Navigate to="/login" />} />
      <Route path="/package" element={isLoggedIn ? <Package /> : <Navigate to="/login" />} />
      <Route path="/user-manage" element={isLoggedIn ? <UserManage /> : <Navigate to="/login" />} />
      <Route path="/user-profile" element={isLoggedIn ? <UserProfileWrapper /> : <Navigate to="/login" />} />
      <Route path="/purchase-order" element={isLoggedIn ? <PurchaseOrder /> : <Navigate to="/login" />} />
      <Route path="/admin-manage" element={isLoggedIn ? <AdminManage /> : <Navigate to="/login" />} />
      <Route path="/setting" element={isLoggedIn ? <Setting /> : <Navigate to="/login" />} />
    </Routes>
  </BrowserRouter>
);