import React from 'react';
import Axios from 'axios';

function Footer() {
  const [version, setVersion] = React.useState();

  React.useEffect(() => {
    // Function to fetch data from the API
    const fetchVersion = async () => {
      try {
        const response = await Axios.get(`${process.env.REACT_APP_API_URL}/`);
        setVersion(response.data.message);
      } catch (error) {
        console.error('Error fetching the version:', error);
      }
    };

    fetchVersion();
  }, []);

  return (
    <div className="Footer">
      <footer className="sticky-footer bg-white">
        <div className="container my-auto">
          <div className="copyright text-center my-auto">
            <span>Copyright © Desigram Studio Version Beta {version}</span>
          </div>
        </div>
      </footer>
    </div >
  );
}

export default Footer;