// Dependency
import React, { useEffect, useState } from 'react';
import Axios from 'axios';
import { Link } from 'react-router-dom';

import '../../../assets/css/user-manage.css';
import Header from 'components/Header/Header';
import Footer from 'components/Footer/Footer';
import Sidebar from 'components/Sidebar/Sidebar';
import Logout from 'components/Modal/Logout';

import { getAllData, deleteByUserId, createAlert } from 'utils/MainUtils';

function UserManage() {
  const [mode, setMode] = useState('S');
  const [userList, setUserList] = useState([]);
  const [userListWithImages, setUserListWithImages] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [usersPerPage] = useState(5); // จำนวนผู้ใช้ที่ต้องการแสดงในแต่ละหน้า
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    initialize();
  }, []);

  useEffect(() => {
    const fetchImages = async () => {
      const usersWithImages = await Promise.all(userList.map(async (user) => {
        const img = await getUserImg(user.user_id);
        return { ...user, img };
      }));
      setUserListWithImages(usersWithImages);
    };

    if (userList.length > 0) {
      fetchImages();
    }
  }, [userList]);

  const initialize = async () => {
    try {
      const userLogin = await getAllData("USER_LOGIN");

      if (userLogin && userLogin.length > 0) {
        const filteredUsers = userLogin.filter(user => user.role === 'U');
        setUserList(filteredUsers);
      }
    } catch (error) {
      createAlert('FAILED', error.message);
    }
  };

  const getUserImg = async (userId) => {
    const imgResponse = await Axios.post(`${process.env.REACT_APP_API_URL}/api/get-user-img`, {
      user_id: userId,
      img_seq: 1
    }, {
      headers: { key: process.env.REACT_APP_ANALYTICS_KEY }
    });
    return imgResponse.data.image;
  };

  const deleteUserById = async (userId) => {
    try {
      const confirmed = window.confirm('Are you sure you want to delete this user?');
      if (!confirmed) return;

      await deleteByUserId("USER_SYS_REF", null, userId);
      await deleteByUserId("USER_LOGIN", null, userId);
      await deleteByUserId("USER_IMG", null, userId);
      await deleteByUserId("USER_INFO", null, userId);
      await deleteByUserId("PERSON_INFO", null, userId);
      await deleteByUserId("FAVORITE_INFO", null, userId);
      await deleteByUserId("DATE_INFO", null, userId);
      await deleteByUserId("INTEREST", null, userId);

      // After deletion, re-fetch the user list
      initialize();
    } catch (error) {
      createAlert('FAILED', error.message);
    }
  };

  // Filtered users based on search term across all users
  const filteredUsers = userListWithImages.filter(user =>
    user.username.toLowerCase().includes(searchTerm.toLowerCase()) ||
    user.email.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Get current users for pagination
  const indexOfLastUser = currentPage * usersPerPage;
  const indexOfFirstUser = indexOfLastUser - usersPerPage;
  const currentUsers = filteredUsers.slice(indexOfFirstUser, indexOfLastUser);

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // Handle search term change
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1); // Reset page to 1 when search term changes
  };

  return (
    <div className="UserManage">
      <div id="wrapper">
        <Sidebar />
        <div id="content-wrapper" className="d-flex flex-column">
          <div id="content">
            <Header />
            {mode === 'S' && (
              <>
                <div className="d-flex align-items-center justify-content-between mb-4" style={{ padding: '2rem' }}>
                  <h1 className="h3 mb-0 text-gray-800 mb-4">User Manage</h1>
                  <button onClick={() => setMode('I')} className="btn btn-success">
                    Add User
                  </button>
                </div>
                <div className="p-3">
                  <div className="input-group mb-3">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search by username or email"
                      value={searchTerm}
                      onChange={handleSearchChange}
                    />
                    <button className="btn btn-outline-secondary" type="button">Search</button>
                  </div>
                  <div className="table-responsive">
                    <table className="table user-list">
                      <thead>
                        <tr>
                          <th>User</th>
                          <th>Created</th>
                          <th className="text-center">Status</th>
                          <th>Email</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {currentUsers.map(user => (
                          <tr key={user.user_id}>
                            <td>
                              <div className="user-avatar">
                                <img src={user.img} alt="Avatar" className="img-circle" />
                                {user.change_password === 'Y' && (
                                  <span className="badge">1</span>
                                )}
                              </div>
                              <Link to={`/user-profile?id=${user.user_id}`}>
                                <span className="user-link">{user.username}</span>
                              </Link>
                            </td>
                            <td>{user.create_datetime.substring(0, 10)}</td>
                            <td className="text-center">
                              <span className={`label ${user.active_flag === 'Y' ? 'label-success' : 'label-danger'}`}>
                                {user.active_flag === 'Y' ? 'Active' : 'Inactive'}
                              </span>
                            </td>
                            <td>
                              <a href="#"><span className="__cf_email__" data-cfemail="3d505c4f5152537d5f4f5c535952135e5250">{user.email}</span></a>
                            </td>
                            <td>
                              <div className="action-buttons">
                                <Link to={`/user-profile?id=${user.user_id}`}>
                                  <button className="btn btn-warning btn-sm me-2">
                                    <i className="fa fa-search-plus"></i>
                                  </button>
                                </Link>
                                <button onClick={() => deleteUserById(user.user_id)} className="btn btn-danger btn-sm">
                                  <i className="fa fa-trash"></i>
                                </button>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  <nav>
                    <ul className="pagination">
                      {Array(Math.ceil(filteredUsers.length / usersPerPage)).fill().map((_, index) => (
                        <li key={index} className={`page-item ${currentPage === index + 1 ? 'active' : ''}`}>
                          <button onClick={() => paginate(index + 1)} className="page-link">{index + 1}</button>
                        </li>
                      ))}
                    </ul>
                  </nav>
                </div>
              </>
            )}
            {mode === 'I' && (
              <div className="text-center">
                <h1>Coming Soon</h1>
                <br />
                <button onClick={() => setMode('S')} className="btn btn-secondary">Back to Search</button>
              </div>
            )}
          </div>
          <Footer />
        </div>
      </div>
      <Logout />
    </div>
  );
}

export default UserManage;