// Dependency
import React, { useEffect, useState } from 'react';
import Axios from 'axios';
import { Link } from 'react-router-dom';

import { FaEye, FaEyeSlash, FaCamera } from 'react-icons/fa';

import '../../../assets/css/user-manage.css';
import Header from 'components/Header/Header';
import Footer from 'components/Footer/Footer';
import Sidebar from 'components/Sidebar/Sidebar';
import Logout from 'components/Modal/Logout';

import { getAllData, deleteByUserId, getDataByUserId, createAlert, base64ToBlob, getFileTypeFromBase64 } from 'utils/MainUtils';

function AdminManage() {
  const [mode, setMode] = useState('S');
  const [userList, setUserList] = useState([]); // **Comment Here
  // const [adminList, setAdminList] = useState([]);
  const [userListWithImages, setUserListWithImages] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [usersPerPage] = useState(5); // จำนวนผู้ใช้ที่ต้องการแสดงในแต่ละหน้า
  const [searchTerm, setSearchTerm] = useState('');
  const [imagePreview, setImagePreview] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [email, setEmail] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [cpassword, setCpassword] = useState('');
  const [firstname, setFirstname] = useState('');
  const [lastname, setLastname] = useState('');
  const [userIdSelected, setUserIdSelected] = useState('');

  useEffect(() => {
    initialize();
  }, []);

  useEffect(() => {
    const fetchImages = async () => {
      const usersWithImages = await Promise.all(userList.map(async (user) => {
        const img = await getUserImg(user.user_id);
        return { ...user, img };
      }));
      setUserListWithImages(usersWithImages);
    };

    if (userList.length > 0) {
      fetchImages();
    }
  }, [userList]);

  const initialize = async () => {
    try {
      const userLogin = await getAllData("USER_LOGIN");

      if (userLogin && userLogin.length > 0) {
        const filteredUsers = userLogin.filter(user => user.role === 'A');
        setUserList(filteredUsers);
      }
    } catch (error) {
      createAlert('FAILED', error.message);
    }
  };

  const onRowSelect = async (userId) => {
    try {
      setMode('U');
      const userLogin = await getDataByUserId('USER_LOGIN', userId, 'Y');
      if (userLogin && userLogin.length > 0) {
        setUserIdSelected(userId);
        setEmail(userLogin[0].email);
        setUsername(userLogin[0].username);
        setPassword(userLogin[0].password);
        setCpassword(userLogin[0].password);
      };

      const adminInfo = await getDataByUserId('ADMIN_INFO', userId, 'Y');
      if (adminInfo && adminInfo.length > 0) {
        setFirstname(adminInfo[0].firstname);
        setLastname(adminInfo[0].lastname);
      };

      //ดึงรูปภาพ
      const imgRequests = [1].map(seq =>
        Axios.post(`${process.env.REACT_APP_API_URL}/api/get-user-img`, {
          user_id: userId,
          img_seq: seq
        }, {
          headers: { key: process.env.REACT_APP_ANALYTICS_KEY }
        })
      );

      const imgResponses = await Promise.all(imgRequests);
      const imgList = imgResponses.map(res => res.data);
      setImagePreview(imgList[0].image);

    } catch (error) {
      createAlert('FAILED', error.message);
    }
  };

  const getUserImg = async (userId) => {
    const imgResponse = await Axios.post(`${process.env.REACT_APP_API_URL}/api/get-user-img`, {
      user_id: userId,
      img_seq: 1
    }, {
      headers: { key: process.env.REACT_APP_ANALYTICS_KEY }
    });
    return imgResponse.data.image;
  };

  const deleteUserById = async (userId) => {
    try {
      const confirmed = window.confirm('Are you sure you want to delete this user?');
      if (!confirmed) return;

      await deleteByUserId("USER_SYS_REF", null, userId);
      await deleteByUserId("USER_LOGIN", null, userId);
      await deleteByUserId("USER_IMG", null, userId);
      await deleteByUserId("USER_INFO", null, userId);
      await deleteByUserId("PERSON_INFO", null, userId);
      await deleteByUserId("FAVORITE_INFO", null, userId);
      await deleteByUserId("DATE_INFO", null, userId);
      await deleteByUserId("INTEREST", null, userId);

      // After deletion, re-fetch the user list
      initialize();
    } catch (error) {
      createAlert('FAILED', error.message);
    }
  };

  // Filtered users based on search term across all users
  const filteredUsers = userListWithImages.filter(user =>
    user.username.toLowerCase().includes(searchTerm.toLowerCase()) ||
    user.email.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Get current users for pagination
  const indexOfLastUser = currentPage * usersPerPage;
  const indexOfFirstUser = indexOfLastUser - usersPerPage;
  const currentUsers = filteredUsers.slice(indexOfFirstUser, indexOfLastUser);

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // Function to toggle password visibility
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  // Function to toggle confirm password visibility
  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  // Function to handle file selection
  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = async () => {
        setImagePreview(reader.result); // Update state with image data
      };
      reader.readAsDataURL(file);
    }
  };

  // Function to simulate click on input
  const handleIconClick = () => {
    document.getElementById('img-file').click();
  };

  //Function Submit Form
  const handleSubmit = async () => {
    try {
      if (password !== cpassword) {
        return createAlert('FAILED', 'password not match');
      };

      const requestData = {
        email: email
        , username: username
        , password: password
        , role: 'A'
        , active_flag: 'Y'
        , create_user: 'Admin'
        , create_process: window.location.pathname
      };
      const response = await Axios.post(`${process.env.REACT_APP_API_URL}/api/create-user-login`, requestData, {
        headers: { key: process.env.REACT_APP_ANALYTICS_KEY }
      });
      if (response.data.status === 'OK') {
        const requestData2 = {
          user_id: response.data.user_id
          , firstname: firstname
          , lastname: lastname
          , active_flag: 'Y'
          , create_user: 'Admin'
          , create_process: window.location.pathname
        };
        const response2 = await Axios.post(`${process.env.REACT_APP_API_URL}/api/create-admin-info`, requestData2, {
          headers: { key: process.env.REACT_APP_ANALYTICS_KEY }
        });

        if (mode === 'U') {
          await handleUploadNewImages(response.data.user_id);
        } else {
          insertUserImg(response.data.user_id, document.getElementById('img-file').files[0], "1");
        }

        if (response.data.status === 'OK' && response2.data.status === 'OK') {
          setMode('S');
          clearValue();
          initialize();
          createAlert('OK', 'Create Admin Successfully');
        };
      };
    } catch (error) {
      createAlert('FAILED', error.message);
    }
  }

  const handleUpdate = async () => {
    try {
      await deleteByUserId("USER_LOGIN", null, userIdSelected);
      await deleteByUserId("USER_IMG", null, userIdSelected);
      await deleteByUserId("ADMIN_INFO", null, userIdSelected);
      await handleSubmit();
    } catch (error) {
      createAlert('FAILED', error.message);
    }
  }

  //INSERT IMAGE
  const insertUserImg = async (userId, imgFile, imgSeq) => {
    try {
      if (!imgFile) return;

      const formData = new FormData();
      formData.append('image', imgFile);

      const uploadResponse = await Axios.post(`${process.env.REACT_APP_API_URL}/api/upload`, formData, {
        headers: { key: process.env.REACT_APP_ANALYTICS_KEY }
      });

      if (uploadResponse.data.status !== 'OK') {
        return createAlert(uploadResponse.data.status, uploadResponse.data.message);
      }

      const imgData = {
        user_id: userId,
        img_type: uploadResponse.data.contentType,
        img_file: uploadResponse.data.fileId,
        img_seq: imgSeq,
        img_size: uploadResponse.data.fileSize,
        active_flag: 'Y',
        create_user: 'Admin',
        create_process: window.location.pathname
      };

      const userImgResponse = await Axios.post(`${process.env.REACT_APP_API_URL}/api/create-user-img`, imgData, {
        headers: { key: process.env.REACT_APP_ANALYTICS_KEY }
      });

      if (userImgResponse.data.status === 'FAILED') {
        return createAlert(userImgResponse.data.status, userImgResponse.data.message);
      }
    } catch (error) {
      createAlert("FAILED", error.message);
    }
  };

  const handleRemovePhoto = async () => {
    setImagePreview(null); // Clear selected image
  };

  const handleUploadNewImages = async (userId) => {
    try {
      const fileType = getFileTypeFromBase64(imagePreview); // แปลง URL เป็น Base64
      const blob = base64ToBlob(imagePreview, fileType); // แปลง Base64 เป็น Blob
      const file = new File([blob], `image_1.${fileType.split('/')[1]}`, { type: fileType });
      await insertUserImg(userId, file, "1");
    } catch (error) {
      createAlert("FAILED", error.message);
    }
  };

  const clearValue = () => {
    setEmail('');
    setUsername('');
    setPassword('');
    setCpassword('');
    setFirstname('');
    setLastname('');
    handleRemovePhoto();
  };

  return (
    <div className="AdminManage">
      <div id="wrapper">
        <Sidebar />
        <div id="content-wrapper" className="d-flex flex-column">
          <div id="content">
            <Header />
            {mode === 'S' && (
              <>
                <div className="d-flex align-items-center justify-content-between mb-4" style={{ padding: '2rem' }}>
                  <h1 className="h3 mb-0 text-gray-800 mb-4">Admin Manage</h1>
                  <button onClick={() => setMode('I') + clearValue()} className="btn btn-success">
                    Add Admin
                  </button>
                </div>
                <div className="p-3">
                  <div className="table-responsive">
                    <table className="table user-list">
                      <thead>
                        <tr>
                          <th>Admin</th>
                          <th>Created</th>
                          <th className="text-center">Status</th>
                          <th>Email</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {currentUsers.map(user => (
                          <tr key={user.user_id}>
                            <td>
                              <div className="user-avatar">
                                <img src={user.img} alt="Avatar" className="img-circle" />
                              </div>
                              <span className="user-link">{user.username}</span>
                            </td>
                            <td>{user.create_datetime.substring(0, 10)}</td>
                            <td className="text-center">
                              <span className={`label ${user.active_flag === 'Y' ? 'label-success' : 'label-danger'}`}>
                                {user.active_flag === 'Y' ? 'Active' : 'Inactive'}
                              </span>
                            </td>
                            <td>
                              <a href="#"><span className="__cf_email__" data-cfemail="3d505c4f5152537d5f4f5c535952135e5250">{user.email}</span></a>
                            </td>
                            <td>
                              <div className="action-buttons">
                                <button className="btn btn-warning btn-sm me-2" onClick={() => onRowSelect(user.user_id)}>
                                  <i className="fa fa-search-plus"></i>
                                </button>
                                <button onClick={() => deleteUserById(user.user_id)} className="btn btn-danger btn-sm">
                                  <i className="fa fa-trash"></i>
                                </button>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  <nav>
                    <ul className="pagination">
                      {Array(Math.ceil(filteredUsers.length / usersPerPage)).fill().map((_, index) => (
                        <li key={index} className={`page-item ${currentPage === index + 1 ? 'active' : ''}`}>
                          <button onClick={() => paginate(index + 1)} className="page-link">{index + 1}</button>
                        </li>
                      ))}
                    </ul>
                  </nav>
                </div>
              </>
            )}
            {(mode === 'I' || mode === 'U') && (
              <>
                <div className="row">
                  <div className="col-sm-12 col-md-12 col-lg-12">
                    <div className="d-flex align-items-center justify-content-between mb-4" style={{ padding: '2rem' }}>
                      <h1 className="h3 mb-0 text-gray-800 mb-4">{mode === 'U' ? 'Edit' : 'Add'} Admin</h1>
                      <button onClick={() => setMode('S')} className="btn btn-success">
                        Back
                      </button>
                    </div>
                  </div>
                </div>
                <div className="row" style={{ paddingLeft: '5rem', paddingRight: '5rem' }}>
                  <div className="col-sm-12 col-md-12 col-lg-6" style={{ backgroundColor: 'white' }}>
                    <div className="form-group mt-3">
                      <label htmlFor="email" className="required-label">Email</label>
                      <input
                        className="form-control form-control-lg"
                        id="email"
                        type="email"
                        name="email"
                        required={true}
                        disabled={mode === 'U' ? true : false}
                        value={email}
                        onChange={(e) => setEmail(e.target.value)} />
                    </div>
                    <div className="form-group mt-3">
                      <label htmlFor="username" className="required-label">Username</label>
                      <input
                        className="form-control form-control-lg"
                        id="username"
                        type="text"
                        name="username"
                        required={true}
                        disabled={mode === 'U' ? true : false}
                        value={username}
                        onChange={(e) => setUsername(e.target.value)} />
                    </div>
                    {mode === 'I' && (
                      <>
                        <div className="form-group mt-3">
                          <label htmlFor="password" className="required-label">Password</label>
                          <div className="input-group">
                            <input
                              className="form-control form-control-lg"
                              id="password"
                              type={showPassword ? "text" : "password"}
                              name="password"
                              required={true}
                              value={password}
                              onChange={(e) => setPassword(e.target.value)} />
                            <button className="btn btn-outline-secondary" type="button" onClick={togglePasswordVisibility}>
                              {showPassword ? <FaEyeSlash /> : <FaEye />}
                            </button>
                          </div>
                        </div>
                        <div className="form-group mt-3">
                          <label htmlFor="c_password" className="required-label">Confirm Password</label>
                          <div className="input-group">
                            <input
                              className="form-control form-control-lg"
                              id="cpassword"
                              type={showConfirmPassword ? "text" : "password"}
                              name="cpassword"
                              required={true}
                              value={cpassword}
                              onChange={(e) => setCpassword(e.target.value)} />
                            <button className="btn btn-outline-secondary" type="button" onClick={toggleConfirmPasswordVisibility}>
                              {showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
                            </button>
                          </div>
                        </div>
                      </>
                    )}
                    <div className="form-group mt-3">
                      <label htmlFor="firstname" className="required-label">Firstname</label>
                      <input
                        className="form-control form-control-lg"
                        id="firstname"
                        type="firstname"
                        name="firstname"
                        required={true}
                        value={firstname}
                        onChange={(e) => setFirstname(e.target.value)} />
                    </div>
                    <div className="form-group mt-3">
                      <label htmlFor="lastname" className="required-label">Lastname</label>
                      <input
                        className="form-control form-control-lg"
                        id="lastname"
                        type="lastname"
                        name="lastname"
                        required={true}
                        value={lastname}
                        onChange={(e) => setLastname(e.target.value)} />
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-12 col-lg-6" style={{ backgroundColor: 'white' }}>
                    <div className="form-group mt-5">
                      <center>
                        <h4>Add your photo</h4>
                        <br />
                        {/* Image input */}
                        <input
                          className="form-control form-control-lg"
                          id="img-file"
                          type="file"
                          name="img-file"
                          style={{ display: 'none' }} // Hide input
                          accept="image/*" // Accept all image types
                          onChange={handleFileChange} // When file changes
                        />
                        {/* Image preview */}
                        {imagePreview && (
                          <div className="mt-3">
                            <div className="circular-preview" style={{ width: '200px', height: '200px', overflow: 'hidden', borderRadius: '50%' }}>
                              <img src={imagePreview} alt="Preview" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                            </div>
                            <br />
                            <a onClick={handleRemovePhoto} style={{ textDecoration: 'underline', color: 'black', cursor: 'pointer' }}>Remove Photo</a>
                          </div>
                        )}
                        {/* Icon for file selection */}
                        {!imagePreview && (
                          <FaCamera
                            style={{ fontSize: "50px", cursor: 'pointer' }}
                            onClick={handleIconClick} // Link to icon click
                          />
                        )}
                      </center>
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-12 col-lg-12" style={{ backgroundColor: 'white' }}>
                    <center>
                      <div className="mt-3" style={{ paddingTop: '1rem', paddingBottom: '3rem' }}>
                        <button onClick={mode === 'U' ? handleUpdate : handleSubmit} className="btn btn-lg btn-danger" style={{ width: '30%' }}>{mode === 'U' ? 'EDIT' : 'SAVE'}</button>
                      </div>
                    </center>
                  </div>
                </div>
                <br /><br />
              </>
            )}
          </div>
          <Footer />
        </div>
      </div>
      <Logout />
    </div>
  );
}

export default AdminManage;