import Axios from "axios";

const getAllData = async (table) => {
    try {
        const response = await Axios.post(`${process.env.REACT_APP_API_URL}/api/get-all-data`, {
            table: table
        }, {
            headers: { key: 'E6EE5F1375B5EF8E28F2A59655FAC' }
        });
        return response.data;
    } catch (error) {
        console.error("Error fetching data:", error);
        return null;
    }
};

const getDataByUserId = async (table, userId, activeFlag) => {
    try {
        const requestBody = {
            table: table,
            user_id: userId
        };

        if (activeFlag !== '') {
            requestBody.active_flag = activeFlag;
        }

        const response = await Axios.post(`${process.env.REACT_APP_API_URL}/api/get-by-user-id`, requestBody, {
            headers: { key: 'E6EE5F1375B5EF8E28F2A59655FAC' }
        });

        return response.data;
    } catch (error) {
        console.error("Error fetching data:", error);
        return null;
    }
};

const getDataByPackageId = async (table, packageId) => {
    try {
        const response = await Axios.post(`${process.env.REACT_APP_API_URL}/api/get-by-package-id`, {
            table: table,
            package_id: packageId
        }, {
            headers: { key: 'E6EE5F1375B5EF8E28F2A59655FAC' }
        });
        return response.data;
    } catch (error) {
        console.error("Error fetching data:", error);
        return null;
    }
};

const getUserSys = async (refTable, userId) => {
    try {
        const response = await Axios.post(`${process.env.REACT_APP_API_URL}/api/get-user-rf`, {
            ref_table: refTable,
            user_id: userId
        }, {
            headers: { key: 'E6EE5F1375B5EF8E28F2A59655FAC' }
        });
        return response.data;
    } catch (error) {
        console.error("Error fetching data:", error);
        return null;
    }
};

const getTextFromReference = async (refTable, refCode, textType) => {
    try {
        const response = await Axios.post(`${process.env.REACT_APP_API_URL}/api/get-value-text`, {
            table: refTable,
            ref_code: refCode
        }, {
            headers: { key: 'E6EE5F1375B5EF8E28F2A59655FAC' }
        });

        const { result } = response.data;
        if (result && result.length > 0) {
            return result[0][textType];
        } else {
            return '-';
        }
    } catch (error) {
        console.error("Error fetching data:", error);
        return null;
    }
};

const getValue2Text = async (refTable, refCode) => {
    return getTextFromReference(refTable, refCode, 'REFERENCE_VALUE_2_TEXT');
};

const getValue3TextByActiveFlag = async (refTable, refCode) => {
    return getTextFromReference(refTable, refCode, 'REFERENCE_VALUE_3_TEXT');
};

const setMaxDate = async () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    const maxDate = `${year}-${month}-${day}`;
    document.getElementById('birth').setAttribute('max', maxDate);
};

const insertUserSysRef = async (userId, refType, refValue, refDesc, activeFlag, createUser, createProcess) => {
    await deleteByUserId("USER_SYS_REF", refType, userId);
    if (refValue !== '') {
        await insertUserSysRefData(userId, refType, refValue, refDesc, activeFlag, createUser, createProcess);
    }
};

const insertUserSysRefData = async (userId, table, code, desc, activeFlag, createUser, createProcess) => {
    try {
        const sysRefData = {
            user_id: userId,
            ref_table_code: table,
            ref_code: code,
            ref_desc: desc,
            active_flag: activeFlag,
            create_user: createUser,
            create_process: createProcess
        };

        const userSysRefResponse = await Axios.post(`${process.env.REACT_APP_API_URL}/api/create-user-sys-ref`, sysRefData, {
            headers: { key: process.env.REACT_APP_ANALYTICS_KEY }
        });

        if (userSysRefResponse.data.status === 'FAILED') {
            return createAlert(userSysRefResponse.data.status, userSysRefResponse.data.message);
        }
    } catch (error) {
        createAlert("FAILED", error.message);
    }
};

const updateStatusActive = async (table, userId, activeFlag, updateUser, updateProcess) => {
    try {
        const formData = {
            table: table,
            user_id: userId,
            active_flag: activeFlag,
            update_user: updateUser,
            update_datetime: new Date(),
            update_process: updateProcess
        };

        const response = await Axios.post(`${process.env.REACT_APP_API_URL}/api/change-status-active`, formData, {
            headers: { key: process.env.REACT_APP_ANALYTICS_KEY }
        });

        if (response.data.status === 'FAILED') {
            return createAlert(response.data.status, response.data.message);
        }
    } catch (error) {
        createAlert("FAILED", error.message);
    }
};

const deleteByUserId = async (table, sub_table, user_id) => {
    try {
        let data = {
            table: table,
            user_id: user_id
        };

        if (sub_table) {
            data.sub_table = sub_table;
        }

        const response = await Axios.post(`${process.env.REACT_APP_API_URL}/api/delete-by-user-id`, data, {
            headers: { key: process.env.REACT_APP_ANALYTICS_KEY }
        });

        if (response.data.status !== 'OK') {
            createAlert(response.data.status, response.data.message);
        }
    } catch (error) {
        createAlert("FAILED", error.message);
    }
};

const createAlert = (status, message) => {
    const alertDiv = document.createElement('div');
    const containerId = status === 'OK' ? 'success-container' : 'error-container';
    alertDiv.setAttribute('role', 'alert');
    alertDiv.className = status === 'OK' ? 'alert alert-primary alert-dismissible fade show' : 'alert alert-danger alert-dismissible fade show';

    const strongElement = document.createElement('strong');
    strongElement.textContent = status === 'OK' ? 'Successfully: ' : 'Error: ';
    alertDiv.appendChild(strongElement);

    const textNode = document.createTextNode(message);
    alertDiv.appendChild(textNode);

    const countdown = document.createElement('span');
    countdown.textContent = ' (Closing in 5 seconds)';
    alertDiv.appendChild(countdown);

    const closeButton = document.createElement('button');
    closeButton.setAttribute('type', 'button');
    closeButton.className = 'btn-close';
    closeButton.setAttribute('data-bs-dismiss', 'alert');
    closeButton.setAttribute('aria-label', 'Close');
    alertDiv.appendChild(closeButton);

    document.getElementById(containerId).appendChild(alertDiv);

    // Initialize countdown
    let secondsLeft = 5;
    const countdownInterval = setInterval(() => {
        secondsLeft -= 1;
        countdown.textContent = ` (Closing in ${secondsLeft} seconds)`;
        if (secondsLeft <= 0) {
            clearInterval(countdownInterval);
            alertDiv.remove();
        }
    }, 1000);

    // Scroll to top of the page
    alertDiv.scrollIntoView({ behavior: 'smooth', block: 'start' });
};

const base64ToBlob = (base64, type = 'application/octet-stream') => {
    const binary = atob(base64.split(',')[1]);
    const array = [];
    for (let i = 0; i < binary.length; i++) {
        array.push(binary.charCodeAt(i));
    }
    return new Blob([new Uint8Array(array)], { type });
};

const getFileTypeFromBase64 = (base64) => {
    const match = base64.match(/^data:(image\/(jpeg|jpg|png|gif));base64,/);
    if (match) {
        return match[1];
    }
    return 'application/octet-stream';
};

const handleEditSection = (sectionIndex) => {
    localStorage.removeItem('section_index');
    localStorage.setItem('section_index', sectionIndex);
    window.location.replace('/edit-profile');
};

export {
    getAllData,
    getDataByUserId,
    getDataByPackageId,
    getUserSys,
    getValue2Text,
    getValue3TextByActiveFlag,
    setMaxDate,
    insertUserSysRef,
    updateStatusActive,
    deleteByUserId,
    createAlert,
    base64ToBlob,
    getFileTypeFromBase64,
    handleEditSection
};