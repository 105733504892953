import React, { useEffect } from 'react';
import Axios from 'axios';
import '../../../assets/css/login.css';
import Footer from 'components/Footer/Footer';
import { createAlert } from 'utils/MainUtils';

function Login() {
  useEffect(() => {
    initialize();
  }, []);

  const initialize = async () => {
    try {
      localStorage.removeItem('a_token');
      localStorage.removeItem('username');
    } catch (error) {
      createAlert('FAILED', error.message);
    }
  };

  const onChangeInput = (id) => {
    if (document.getElementById(id).value === '') {
      document.getElementById(id).addEventListener('change', function (event) {
        document.getElementById(id).value = event.target.value;
      });
    }
  };

  const handleLogin = async (event) => {
    event.preventDefault(); // ป้องกันการรีเฟรชหน้า
    try {
      const username = document.getElementById('login_username').value;
      const password = document.getElementById('login_password').value;

      if (username === "" || password === "") {
        createAlert("FAILED", "Please fill in Username or Password");
      } else {
        console.log('Logging in with:', { username, password }); // ดูข้อมูลที่กำลังส่ง

        const response = await Axios.post(`${process.env.REACT_APP_API_URL}/api/admin/login`,
          {
            username,
            password
          },
          {
            headers: {
              key: process.env.REACT_APP_ANALYTICS_KEY,
            }
          }
        );

        console.log('Response from login:', response.data); // ดูข้อมูลที่ได้รับกลับมา

        if (response.data.status === 'OK') {
          const res = await Axios.get(`${process.env.REACT_APP_API_URL}/api/get-user-by-username/${username}`, {
            headers: { key: process.env.REACT_APP_ANALYTICS_KEY }
          });

          console.log('Response from get user:', res.data); // ดูข้อมูลที่ได้รับกลับมา

          if (res.data.status === 'FAILED') {
            localStorage.removeItem('a_token');
            localStorage.removeItem('username');
            window.location.replace("/login");
          } else {
            localStorage.setItem('a_token', response.data.token);
            localStorage.setItem('username', res.data.user[0].username);
            window.location.replace("/");
          }
        } else {
          localStorage.removeItem('a_token');
          localStorage.removeItem('username');
          createAlert(response.data.status, response.data.message);
        }
      }
    } catch (error) {
      createAlert("FAILED", error.message);
    }
  };

  return (
    <div className="Login">
      <div id="success-container"></div>
      <div id="error-container"></div>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 col-md-8 col-lg-6">
            <div className="card o-hidden border-0 shadow-lg my-5">
              <div className="card-body p-0">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="p-5">
                      <div className="text-center">
                        <h1 className="h4 text-gray-900 mb-4">Welcome Administrator!</h1>
                      </div>
                      <form className="user" onSubmit={handleLogin}>
                        <div className="form-group">
                          <label>Username</label>
                          <input className="form-control form-control-lg" id="login_username" type="text" name="login_username"
                            onChange={() => onChangeInput('login_username')} />
                        </div>
                        <div className="form-group">
                          <label>Password</label>
                          <input className="form-control form-control-lg" id="login_password" type="password" name="login_password"
                            onChange={() => onChangeInput('login_password')} />
                        </div>
                        <button type="submit" className="btn btn-primary btn-user btn-block">
                          Login
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Login;