import React, { useEffect, useState } from 'react';
import Axios from 'axios';
import { useLocation } from 'react-router-dom';

import '../../../assets/css/user-profile.css';
import Header from 'components/Header/Header';
import Footer from 'components/Footer/Footer';
import Sidebar from 'components/Sidebar/Sidebar';
import Logout from 'components/Modal/Logout';
import PreviewsToggle from 'Toggle/PreviewsToggle';

import { getDataByUserId, updateStatusActive, deleteByUserId, createAlert } from 'utils/MainUtils';

function UserProfile() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const userId = queryParams.get("id");

  const [userImgList, setUserImgList] = useState([]);
  const [userLogin, setUserLogin] = useState({});
  const [userInfo, setUserInfo] = useState([]);
  const [personInfo, setPersonInfo] = useState([]);
  const [favoriteInfo, setFavoriteInfo] = useState([]);
  const [dateInfo, setDateInfo] = useState([]);

  useEffect(() => {
    const fetchUserImgs = async () => {
      try {
        const imgSeqList = [1, 2, 3];
        const promises = imgSeqList.map(seq =>
          Axios.post(`${process.env.REACT_APP_API_URL}/api/get-user-img`, {
            user_id: userId,
            img_seq: seq
          }, {
            headers: { key: process.env.REACT_APP_ANALYTICS_KEY }
          })
        );

        const responses = await Promise.all(promises);
        const images = responses.map(response => response.data.image);
        setUserImgList(images);
      } catch (error) {
        createAlert("FAILED", error.message);
      }
    };

    const fetchUserData = async () => {
      try {
        const [loginData, infoData, personData, favoriteData, dateData] = await Promise.all([
          getDataByUserId("USER_LOGIN", userId),
          getDataByUserId("USER_INFO", userId),
          getDataByUserId("PERSON_INFO", userId),
          getDataByUserId("FAVORITE_INFO", userId),
          getDataByUserId("DATE_INFO", userId)
        ]);

        if (loginData && loginData.length > 0) {
          setUserLogin(loginData[0]);
        }

        if (infoData && infoData.length > 0) {
          setUserInfo(infoData);
        }

        if (personData && personData.length > 0) {
          setPersonInfo(personData);
        }

        if (favoriteData && favoriteData.length > 0) {
          setFavoriteInfo(favoriteData);
        }

        if (dateData && dateData.length > 0) {
          setDateInfo(dateData);
        }
      } catch (error) {
        createAlert("FAILED", error.message);
      }
    };

    fetchUserImgs();
    fetchUserData();
  }, [userId]);

  const updateStatusChangePass = async () => {
    const isConfirmed = window.confirm("Are you sure you want to approve the password change?");
    if (!isConfirmed) {
      return;
    }

    try {
      // ส่งอีเมล
      await Axios.post(`${process.env.REACT_APP_API_URL}/api/send-email`, {
        username: userLogin.username,
        email: userLogin.email
      }, {
        headers: { key: process.env.REACT_APP_ANALYTICS_KEY }
      });

      // Update Status กลับไปเป็นเหมือนเดิม
      const response = await Axios.post(`${process.env.REACT_APP_API_URL}/api/update-change-password`, {
        email: userLogin.email,
        status: 'N'
      }, {
        headers: { key: process.env.REACT_APP_ANALYTICS_KEY }
      });

      if (response.data.status === 'OK') {
        createAlert("OK", "Password Change Confirmation");
        setUserLogin(prevState => ({ ...prevState, change_password: 'N' }));
      } else {
        createAlert(response.data.status, response.data.message);
      }
    } catch (error) {
      createAlert("FAILED", error.message);
    }
  };

  const updateStatusActiveFlag = async (status) => {
    let isConfirmed = false;

    if (status === 'show') {
      isConfirmed = window.confirm("Are you sure you want to show profile?");
    } else if (status === 'hide') {
      isConfirmed = window.confirm("Are you sure you want to hide profile?");
    }

    if (!isConfirmed) {
      return;
    }

    try {
      const updateResponse = async (table) => {
        return await updateStatusActive(table, userId, status === 'show' ? "Y" : "N", "Admin", window.location.pathname);
      }

      await Promise.all([
        updateResponse("USER_INFO"),
        updateResponse("PERSON_INFO"),
        updateResponse("FAVORITE_INFO"),
        updateResponse("DATE_INFO")
      ]);

      window.location.reload();
    } catch (error) {
      createAlert("FAILED", error.message);
    }
  };

  const deleteUserById = async () => {
    try {
      const confirmed = window.confirm('Are you sure you want to delete this user?');
      if (!confirmed) return;

      await deleteByUserId("USER_SYS_REF", null, userId);
      await deleteByUserId("USER_LOGIN", null, userId);
      await deleteByUserId("USER_IMG", null, userId);
      await deleteByUserId("USER_INFO", null, userId);
      await deleteByUserId("PERSON_INFO", null, userId);
      await deleteByUserId("FAVORITE_INFO", null, userId);
      await deleteByUserId("DATE_INFO", null, userId);
      await deleteByUserId("INTEREST", null, userId);

      window.location.replace("/user-manage");
    } catch (error) {
      createAlert('FAILED', error.message);
    }
  };

  return (
    <div className="UserProfile">
      <div id="wrapper">
        <Sidebar />
        <div id="content-wrapper" className="d-flex flex-column">
          <div id="content">
            <Header />
            <div className="container">
              <div className="row">
                <div className="col-md-3">
                  <div className="osahan-account-page-left shadow-sm bg-white h-100">
                    <div className="border-bottom p-4">
                      <div className="osahan-user text-center">
                        <div className="osahan-user-media">
                          {userImgList.length > 0 && (
                            <img className="mb-3" src={userImgList[0]} alt="user" />
                          )}
                          <div className="osahan-user-media-body" style={{ textAlign: 'left', padding: '1rem' }}>
                            <p className="mb-3"><b>Username: </b>{userLogin.username}</p>
                            <p className="mb-3"><b>Role: </b>{userLogin.role === 'A' ? 'Admin' : 'User'}</p>
                            <p><b>Email: </b>{userLogin.email}</p>
                            {userInfo.length > 0 && (
                              <>
                                <p className="text-gray mb-3"><b>Name: </b>{userInfo[0].name}</p>
                                <p className="text-gray mb-3"><b>Occupation: </b>{userInfo[0].occupation}</p>
                                <p className="text-gray mb-3"><b>Birthday: </b>{userInfo[0].birthdate}</p>
                                <p className="text-gray mb-3"><b>Country: </b>{userInfo[0].country}</p>
                                <p className="text-gray mb-3"><b>Caption: </b>{userInfo[0].caption}</p>
                              </>
                            )}
                          </div>
                          <div className="row mt-2">
                            <button
                              onClick={() => updateStatusChangePass()}
                              className="btn btn-success"
                              disabled={userLogin.change_password === 'N' ? true : false}>
                              Approve Password Change
                            </button>
                          </div>
                          <div className="row mt-2">
                            {userInfo.length > 0 && (
                              <>
                                <button
                                  onClick={() => updateStatusActiveFlag('hide')}
                                  className="btn btn-warning"
                                  hidden={userInfo[0].active_flag === 'Y' ? false : true}>
                                  Hide Profile
                                </button>
                                <button
                                  onClick={() => updateStatusActiveFlag('show')}
                                  className="btn btn-info"
                                  hidden={userInfo[0].active_flag === 'Y' ? true : false}>
                                  Show Profile
                                </button>
                              </>
                            )}
                          </div>
                          <div className="row mt-2">
                            <button
                              onClick={() => deleteUserById()}
                              className="btn btn-danger">
                              Delete User
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="container">
                      <div className="row mt-2">
                        {userImgList.map((img, index) => (
                          <div className="col-4" key={index}>
                            <img className="img-thumbnail mb-2 equal-height-img" src={img} alt={`user-img-${index + 1}`} />
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-9">
                  <div className="osahan-account-page-right shadow-sm bg-white p-4 h-100">
                    <div className="tab-content" id="myTabContent">
                      <div className="tab-pane fade active show" id="favourites" role="tabpanel" aria-labelledby="favourites-tab">
                        <div className="row" style={{ padding: "1rem" }}>
                          {personInfo.length > 0 && (
                            <>
                              <div className="col-sm-12 col-md-6 col-lg-4">
                                <b id="h6-header">Height</b>
                                <p id="p-detail">{personInfo[0].height}</p>
                              </div>
                              <div className="col-sm-12 col-md-6 col-lg-4">
                                <b id="h6-header">Weight</b>
                                <p id="p-detail">{personInfo[0].weight}</p>
                              </div>
                              <div className="col-sm-12 col-md-6 col-lg-4">
                                <b id="h6-header">Number of Children</b>
                                <p id="p-detail">{personInfo[0].num_child}</p>
                              </div>
                              {favoriteInfo.length > 0 && (
                                <>
                                  <div className="col-sm-12 col-md-6 col-lg-4">
                                    <b id="h6-header">Free Time</b>
                                    <p id="p-detail">{favoriteInfo[0].free_time}</p>
                                  </div>
                                  {dateInfo.length > 0 && (
                                    <>
                                      <div className="col-sm-12 col-md-6 col-lg-4">
                                        <b id="h6-header">Age range of interest</b>
                                        <p id="p-detail">{dateInfo[0].age_start} - {dateInfo[0].age_end} years old</p>
                                      </div>
                                      <div className="col-sm-12 col-md-6 col-lg-4">
                                        <b id="h6-header">Why do you want to have a Thai spouse?</b>
                                        <p id="p-detail">{dateInfo[0].th_spouse_reason}</p>
                                      </div>
                                      <div className="col-sm-12 col-md-6 col-lg-4">
                                        <b id="h6-header">What do you think about Thai woman/man?</b>
                                        <p id="p-detail">{dateInfo[0].th_women_think}</p>
                                      </div>
                                      <div className="col-sm-12 col-md-6 col-lg-4">
                                        <b id="h6-header">What do you expect from your future spouse when you marry him/her?</b>
                                        <p id="p-detail">{dateInfo[0].spouse_expect}</p>
                                      </div>
                                    </>
                                  )}
                                </>
                              )}
                            </>
                          )}
                          <div className="row"
                            style={{ padding: "1rem" }}>
                            <div className="col-sm-12 col-md-6 col-lg-4 mb-4">
                              <b id="h6-header">Shape</b>
                              <PreviewsToggle id={userId} button={true} rf={"SHAPE"} />
                            </div>
                            <div className="col-sm-12 col-md-6 col-lg-4 mb-4">
                              <b id="h6-header">Marital Status</b>
                              <PreviewsToggle id={userId} button={true} rf={"MARITAL_STAT"} />
                            </div>
                            <div className="col-sm-12 col-md-6 col-lg-4 mb-4">
                              <b id="h6-header">Kids</b>
                              <PreviewsToggle id={userId} button={true} rf={"KIDS"} />
                            </div>
                          </div>
                          <div className="row"
                            style={{ padding: "1rem" }}>
                            <div className="col-sm-12 col-md-6 col-lg-4 mb-4">
                              <b id="h6-header">Who do you live with?</b>
                              <PreviewsToggle id={userId} button={true} rf={"LIVE_WITH"} />
                            </div>
                            <div className="col-sm-12 col-md-6 col-lg-4 mb-4">
                              <b id="h6-header">Who influences you the most?</b>
                              <PreviewsToggle id={userId} button={true} rf={"INFLUENCES_FACTOR"} />
                            </div>
                            <div className="col-sm-12 col-md-6 col-lg-4 mb-4">
                              <b id="h6-header">Ethnicity</b>
                              <PreviewsToggle id={userId} button={true} rf={"ETHNICITY"} />
                            </div>
                          </div>
                          <div className="row"
                            style={{ padding: "1rem" }}>
                            <div className="col-sm-12 col-md-6 col-lg-4 mb-4">
                              <b id="h6-header">Religion</b>
                              <PreviewsToggle id={userId} button={true} rf={"RELIGION"} />
                            </div>
                            <div className="col-sm-12 col-md-6 col-lg-4 mb-4">
                              <b id="h6-header">What is your zodiac sign?</b>
                              <PreviewsToggle id={userId} button={true} rf={"ZODIAC"} />
                            </div>
                            <div className="col-sm-12 col-md-6 col-lg-4 mb-4">
                              <b id="h6-header">Education</b>
                              <PreviewsToggle id={userId} button={true} rf={"EDUCATION"} />
                            </div>
                          </div>
                          <div className="row"
                            style={{ padding: "1rem" }}>
                            <div className="col-sm-12 col-md-6 col-lg-4 mb-4">
                              <b id="h6-header">Religion</b>
                              <PreviewsToggle id={userId} button={true} rf={"PERSON_VALUE"} />
                            </div>
                            <div className="col-sm-12 col-md-6 col-lg-4 mb-4">
                              <h6 id="h6-header">How often do you drink?</h6>
                              <PreviewsToggle id={userId} button={true} rf={"DRINKING"} />
                            </div>
                            <div className="col-sm-12 col-md-6 col-lg-4 mb-4">
                              <h6 id="h6-header">Do you Smoking?</h6>
                              <PreviewsToggle id={userId} button={true} rf={"SMOKING"} />
                            </div>
                          </div>
                          <div className="row"
                            style={{ padding: "1rem" }}>
                            <div className="col-sm-12 col-md-6 col-lg-4 mb-4">
                              <b id="h6-header">What do you like?</b>
                              <PreviewsToggle id={userId} button={true} rf={"FAVORITE"} />
                            </div>
                            <div className="col-sm-12 col-md-6 col-lg-4 mb-4">
                              <b id="h6-header">Favorite food</b>
                              <PreviewsToggle id={userId} button={true} rf={"FOOD"} />
                            </div>
                            <div className="col-sm-12 col-md-6 col-lg-4 mb-4">
                              <b id="h6-header">Favorite sport</b>
                              <PreviewsToggle id={userId} button={true} rf={"SPORT"} />
                            </div>
                          </div>
                          <div className="row"
                            style={{ padding: "1rem" }}>
                            <div className="col-sm-12 col-md-6 col-lg-4 mb-4">
                              <b id="h6-header">What is your sexual orientation?</b>
                              <PreviewsToggle id={userId} button={true} rf={"SEXUAL_ORIEN"} />
                            </div>
                            <div className="col-sm-12 col-md-6 col-lg-4 mb-4">
                              <b id="h6-header">Causes and communities</b>
                              <PreviewsToggle id={userId} button={true} rf={"COMMUNITY"} />
                            </div>
                            <div className="col-sm-12 col-md-6 col-lg-4 mb-4">
                              <b id="h6-header">Politics</b>
                              <PreviewsToggle id={userId} button={true} rf={"POLITICS"} />
                            </div>
                          </div>

                          <div className="row"
                            style={{ padding: "1rem" }}>
                            <div className="col-sm-12 col-md-6 col-lg-4 mb-4">
                              <b id="h6-header">What are you looking for?</b>
                              <PreviewsToggle id={userId} button={true} rf={"LOOKING_FOR"} />
                            </div>
                            <div className="col-sm-12 col-md-6 col-lg-4 mb-4">
                              <b id="h6-header">Gender looking for?</b>
                              <PreviewsToggle id={userId} button={true} rf={"GENDER_FOR"} />
                            </div>
                            <div className="col-sm-12 col-md-6 col-lg-4 mb-4">
                              <b id="h6-header">Are you interested in learning Thai?</b>
                              <PreviewsToggle id={userId} button={true} rf={"LEARN_TH"} />
                            </div>
                          </div>
                          <div className="row"
                            style={{ padding: "1rem" }}>
                            <div className="col-sm-12 col-md-12 col-lg-4 mb-4">
                              <b id="h6-header">Do you think the language used in communication is important?</b>
                              <PreviewsToggle id={userId} button={true} rf={"LANG_IMPORTANT"} />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
      <Logout />
    </div>
  );
}

export default UserProfile;