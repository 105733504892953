import React from 'react';
import { useLocation, Link } from 'react-router-dom';

function Sidebar() {
    const location = useLocation();

    return (
        <div className="Sidebar">
            <ul className="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion" id="accordionSidebar"
                style={{ minHeight: '100vh', position: 'sticky', top: 0 }}>
                <a className="sidebar-brand d-flex align-items-center justify-content-center">
                    <div className="sidebar-brand-icon rotate-n-15">
                        <i className="fas fa-laugh-wink" />
                    </div>
                    <div className="sidebar-brand-text mx-3">Admin Thainorway</div>
                </a>
                <hr className="sidebar-divider my-0" />
                <li className={`nav-item ${location.pathname === '/' ? 'active' : ''}`}>
                    <Link className="nav-link" to="/">
                        <i className="fas fa-fw fa-tachometer-alt" />
                        <span style={{ padding: '0.5rem' }}>Dashboard</span>
                    </Link>
                </li>
                <li className={`nav-item ${location.pathname === '/package' ? 'active' : ''}`}>
                    <Link className="nav-link" to="/package">
                        <i className="fa fa-archive" />
                        <span style={{ padding: '0.5rem' }}>Package</span>
                    </Link>
                </li>
                <li className={`nav-item ${location.pathname === '/user-manage' ? 'active' : ''}`}>
                    <Link className="nav-link" to="/user-manage">
                        <i className="fa fa-user" />
                        <span style={{ padding: '0.5rem' }}>User Manage</span>
                    </Link>
                </li>
                <li className={`nav-item ${location.pathname === '/purchase-order' ? 'active' : ''}`}>
                    <Link className="nav-link" to="/purchase-order">
                        <i className="fa fa-shopping-cart" />
                        <span style={{ padding: '0.5rem' }}>Purchase Order</span>
                    </Link>
                </li>
                <li className={`nav-item ${location.pathname === '/admin-manage' ? 'active' : ''}`}>
                    <Link className="nav-link" to="/admin-manage">
                        <i className="fa fa-users" />
                        <span style={{ padding: '0.5rem' }}>Admin Manage</span>
                    </Link>
                </li>
                <li className={`nav-item ${location.pathname === '/setting' ? 'active' : ''}`}>
                    <Link className="nav-link" to="/setting">
                        <i className="fa fa-cog" />
                        <span style={{ padding: '0.5rem' }}>Setting</span>
                    </Link>
                </li>
                <div style={{ flex: '1' }}></div> {/* This div will push the footer down */}
            </ul>
        </div>
    );
}

export default Sidebar;