import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import { addDays, addMonths, addYears } from 'date-fns';

import '../../../assets/css/purchase-order.css';
import Header from 'components/Header/Header';
import Footer from 'components/Footer/Footer';
import Sidebar from 'components/Sidebar/Sidebar';
import Logout from 'components/Modal/Logout';

import { getAllData, getDataByUserId, getDataByPackageId, getValue2Text, createAlert } from 'utils/MainUtils';

function PurchaseOrder() {
  const [purchaseUsers, setPurchaseUsers] = useState([]);
  const [userNames, setUserNames] = useState({});
  const [packageNames, setPackageNames] = useState({});
  const [images, setImages] = useState({});
  const [loading, setLoading] = useState(true);
  const [statusText, setStatusText] = useState({});
  const [selectedPurchase, setSelectedPurchase] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const [filteredUsers, setFilteredUsers] = useState([]);
  const [filterStatus, setFilterStatus] = useState('WA');

  useEffect(() => {
    initialize();
  }, []);

  useEffect(() => {
    if (filterStatus === 'ALL') {
      setFilteredUsers(purchaseUsers);
    } else {
      setFilteredUsers(purchaseUsers.filter(item => item.purchase_status === filterStatus));
    }
  }, [filterStatus, purchaseUsers]);

  const initialize = async () => {
    try {
      const response = await getAllData('PURCHASE_ORDER');

      if (response.length > 0) {
        setPurchaseUsers(response);
        await fetchUserNames(response);
        await fetchPackageNames(response);
        await fetchImages(response);
        await fetchStatusText(response);
        setLoading(false);
      } else {
        setPurchaseUsers([]);
        setLoading(false);
      }
    } catch (error) {
      createAlert('FAILED', error.message);
      setLoading(false);
    }
  };

  const fetchUserNames = async (users) => {
    try {
      const names = {};
      for (const user of users) {
        const response = await getDataByUserId('USER_INFO', user.user_id);
        names[user.user_id] = response[0].name;
      }
      setUserNames(names);
    } catch (error) {
      createAlert('FAILED', error.message);
    }
  };

  const fetchPackageNames = async (packages) => {
    try {
      const names = {};
      const periodTime = {};
      const periodUnit = {};
      for (const pack of packages) {
        const response = await getDataByPackageId('PACKAGE', pack.package_id);
        names[pack.package_id] = response[0].name;
        periodTime[pack.package_id] = response[0].period_time;
        periodUnit[pack.package_id] = response[0].period_unit;
      }
      setPackageNames(names);
    } catch (error) {
      createAlert('FAILED', error.message);
    }
  };

  const fetchImages = async (purchases) => {
    try {
      const imgs = {};
      for (const purchase of purchases) {
        if (purchase.purchase_img_file) {
          const response = await Axios.post(`${process.env.REACT_APP_API_URL}/api/get-purchase-img`, { purchase_id: purchase.purchase_id }, {
            headers: { key: 'E6EE5F1375B5EF8E28F2A59655FAC' }
          });
          imgs[purchase.purchase_id] = response.data.image;
        }
      }
      setImages(imgs);
    } catch (error) {
      createAlert('FAILED', error.message);
    }
  };

  const fetchStatusText = async (purchases) => {
    try {
      const texts = {};
      for (const purchase of purchases) {
        const text = await getValue2Text('PURCHASE_STATUS', purchase.purchase_status);
        texts[purchase.purchase_id] = text;
      }
      setStatusText(texts);
    } catch (error) {
      createAlert('FAILED', error.message);
    }
  };

  const formatDateTime = (datetime) => {
    if (!datetime) return ''; // Check if datetime exists

    const date = new Date(datetime);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    return `${day}/${month}/${year} ${hours}:${minutes}`;
  };

  const handleImageClick = (purchase) => {
    setSelectedPurchase(purchase);
    setShowModal(true);
  };

  // Handle close modal
  const handleCloseModal = () => {
    setSelectedPurchase(null);
    setShowModal(false);
  };

  const handleFilterChange = (e) => {
    setFilterStatus(e.target.value);
  };

  if (loading) {
    return (
      <>
        <div id="wrapper">
          <Sidebar />
          <div id="content-wrapper" className="d-flex flex-column">
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100vh' }}>
              <h1 className="waiting-text">Loading</h1>
            </div>
            <Footer />
          </div>
        </div>
      </>
    );
  }

  const handleApprove = async () => {
    const confirmed = window.confirm("Are you sure you want to approve this purchase?");
    if (confirmed) {
      try {
        const response = await Axios.post(
          `${process.env.REACT_APP_API_URL}/api/update-purchase-status`,
          {
            purchase_id: selectedPurchase.purchase_id,
            purchase_status: 'AP',
            purchase_approve_date: new Date(),
          },
          {
            headers: { key: 'E6EE5F1375B5EF8E28F2A59655FAC' },
          }
        );

        if (response.data.status === 'OK') {
          console.log(selectedPurchase.package_id);
          const packageData = await getDataByPackageId('PACKAGE', selectedPurchase.package_id);
          console.log(packageData);
          const periodTime = packageData[0].period_time;
          const periodUnit = packageData[0].period_unit;
          let memberEnd = new Date();

          // Calculate member end date based on periodTime and periodUnit
          if (periodUnit === 'D') {
            memberEnd = addDays(memberEnd, periodTime);
          } else if (periodUnit === 'M') {
            memberEnd = addMonths(memberEnd, periodTime);
          } else if (periodUnit === 'Y') {
            memberEnd = addYears(memberEnd, periodTime);
          }

          const result = await Axios.post(
            `${process.env.REACT_APP_API_URL}/api/create-user-member`,
            {
              user_id: selectedPurchase.user_id,
              purchase_id: selectedPurchase.purchase_id,
              member_start: new Date(),
              member_end: memberEnd,
              member_status: 'N',
              active_flag: 'Y',
              create_user: 'Admin',
              create_datetime: new Date(),
              create_process: window.location.pathname
            },
            {
              headers: { key: 'E6EE5F1375B5EF8E28F2A59655FAC' },
            }
          );
          if (result.data.status === 'OK') {
            window.location.reload();
          } else {
            createAlert(result.data.status, result.data.message);
          }
        } else {
          createAlert(response.data.status, response.data.message);
        }
      } catch (error) {
        createAlert('FAILED', error.message);
      }
    }
  };

  const handleReject = async () => {
    const confirmed = window.confirm("Are you sure you want to reject this purchase?");
    if (confirmed) {
      try {
        const response = await Axios.post(
          `${process.env.REACT_APP_API_URL}/api/update-purchase-status`,
          {
            purchase_id: selectedPurchase.purchase_id,
            purchase_status: 'RJ',
            purchase_approve_date: new Date(),
          },
          {
            headers: { key: 'E6EE5F1375B5EF8E28F2A59655FAC' },
          }
        );

        if (response.data.status === 'OK') {
          window.location.reload();
        }
      } catch (error) {
        createAlert('FAILED', error.message);
      }
    }
  };

  const handleReturn = async (purchase) => {
    const confirmed = window.confirm("Are you sure you want to return this purchase?");
    if (confirmed) {
      try {
        const response = await Axios.post(
          `${process.env.REACT_APP_API_URL}/api/update-purchase-status`,
          {
            purchase_id: purchase.purchase_id,
            purchase_status: 'WA',
            purchase_approve_date: new Date(),
          },
          {
            headers: { key: 'E6EE5F1375B5EF8E28F2A59655FAC' },
          }
        );

        if (response.data.status === 'OK') {
          window.location.reload();
        }
      } catch (error) {
        createAlert('FAILED', error.message);
      }
    }
  };

  const handleCancel = async (purchase) => {
    const confirmed = window.confirm("Are you sure you want to cancel this purchase?");
    if (confirmed) {
      try {
        const response = await Axios.post(
          `${process.env.REACT_APP_API_URL}/api/update-purchase-status`,
          {
            purchase_id: purchase.purchase_id,
            purchase_status: 'CC',
            purchase_approve_date: new Date(),
          },
          {
            headers: { key: 'E6EE5F1375B5EF8E28F2A59655FAC' },
          }
        );

        if (response.data.status === 'OK') {
          window.location.reload();
        }
      } catch (error) {
        createAlert('FAILED', error.message);
      }
    }
  };

  return (
    <div className="PurchaseOrder">
      <div id="wrapper">
        <Sidebar />
        <div id="content-wrapper" className="d-flex flex-column">
          <div id="content">
            <Header />
            <>
              <div className="d-flex align-items-center justify-content-between mb-4" style={{ padding: '2rem' }}>
                <h1 className="h3 mb-0 text-gray-800 mb-4">Purchase Order</h1>
                <div>
                  <label className="form-label">Purchase Status Filter: </label>
                  <div className="d-flex flex-wrap">
                    <div className="form-check form-check-inline">
                      <input className="form-check-input" type="radio" name="statusFilter" id="statusAll" value="ALL" checked={filterStatus === 'ALL'} onChange={handleFilterChange} />
                      <label className="form-check-label" htmlFor="statusAll">All</label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input className="form-check-input" type="radio" name="statusFilter" id="statusWA" value="WA" checked={filterStatus === 'WA'} onChange={handleFilterChange} />
                      <label className="form-check-label" htmlFor="statusWA">Wait Approve</label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input className="form-check-input" type="radio" name="statusFilter" id="statusWP" value="WP" checked={filterStatus === 'WP'} onChange={handleFilterChange} />
                      <label className="form-check-label" htmlFor="statusWA">Wait Payment</label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input className="form-check-input" type="radio" name="statusFilter" id="statusAP" value="AP" checked={filterStatus === 'AP'} onChange={handleFilterChange} />
                      <label className="form-check-label" htmlFor="statusAP">Approve</label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input className="form-check-input" type="radio" name="statusFilter" id="statusRJ" value="RJ" checked={filterStatus === 'RJ'} onChange={handleFilterChange} />
                      <label className="form-check-label" htmlFor="statusRJ">Reject</label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input className="form-check-input" type="radio" name="statusFilter" id="statusCC" value="CC" checked={filterStatus === 'CC'} onChange={handleFilterChange} />
                      <label className="form-check-label" htmlFor="statusCC">Cancel</label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="p-3">
                <div className="table-responsive">
                  <table className="table user-list">
                    <thead>
                      <tr>
                        <th scope="col">User</th>
                        <th scope="col">Package</th>
                        <th scope="col">Purchase Amount</th>
                        <th scope="col">Purchase Date</th>
                        <th scope="col">Purchase Approve Date</th>
                        <th scope="col">Purchase Receipt</th>
                        <th scope="col">Purchase Image</th>
                        <th scope="col">Purchase Status</th>
                        <th scope="col">Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {Array.isArray(purchaseUsers) && purchaseUsers.length > 0 ? (
                        filteredUsers.map((item, index) => (
                          <tr key={item.purchase_id}>
                            <td>{userNames[item.user_id] || 'Loading...'}</td>
                            <td>{packageNames[item.package_id] || 'Loading...'}</td>
                            <td>{item.purchase_amt} ฿</td>
                            <td>{formatDateTime(item.purchase_date)}</td>
                            <td>{formatDateTime(item.purchase_approve_date)}</td>
                            <td>{formatDateTime(item.purchase_receipt)}</td>
                            <td>
                              {images[item.purchase_id] ? (
                                <img
                                  src={images[item.purchase_id]}
                                  alt="Purchase"
                                  className="purchase-image img-fluid"
                                  onClick={() => handleImageClick(item)}
                                />
                              ) : (
                                <span>No Image Available</span>
                              )}
                            </td>
                            <td>
                              {item.purchase_status === 'WA' || item.purchase_status === 'CC' ? (
                                <button className="btn btn-danger" style={{ pointerEvents: 'none', cursor: 'not-allowed' }}>
                                  {statusText[item.purchase_id] || '-'}
                                </button>
                              ) : item.purchase_status === 'AP' ? (
                                <button className="btn btn-success" style={{ pointerEvents: 'none', cursor: 'not-allowed' }}>
                                  {statusText[item.purchase_id] || '-'}
                                </button>
                              ) : (
                                <button className="btn btn-secondary" style={{ pointerEvents: 'none', cursor: 'not-allowed' }}>
                                  {statusText[item.purchase_id] || '-'}
                                </button>
                              )}
                            </td>
                            <td>
                              <button className="btn btn-success btn-sm" onClick={() => handleImageClick(item)}>
                                <i className="fas fa-search"></i>
                              </button>
                              {/* {item.purchase_status === 'CC' ? (  
                                  <button className="btn btn-info btn-sm" onClick={() => handleReturn(item)}>
                                    <i className="fas fa-undo"></i>
                                  </button>
                                ) : (
                                  <button className="btn btn-danger btn-sm" onClick={() => handleCancel(item)}>
                                    <i className="fas fa-times"></i>
                                  </button>
                                )} */}
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="8">
                            <center>
                              <h2>No Data</h2>
                            </center>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </>
          </div>
          <Footer />
        </div>
      </div>

      {/* Bootstrap Modal for displaying selected purchase details */}
      {selectedPurchase && (
        <div className={`modal fade ${showModal ? 'show' : ''}`} style={{ display: showModal ? 'block' : 'none' }} tabIndex="-1" aria-labelledby="purchaseModal" aria-hidden={!showModal}>
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="purchaseModal">Purchase Details</h5>
                <button type="button" className="btn-close" aria-label="Close" onClick={handleCloseModal}></button>
              </div>
              <div className="modal-body">
                <div>
                  <h5 className="fw-bold modal-label">User:</h5>
                  <p className="modal-text">{userNames[selectedPurchase.user_id]}</p>
                </div>
                <div>
                  <h5 className="fw-bold modal-label">Package:</h5>
                  <p className="modal-text">{packageNames[selectedPurchase.package_id]}</p>
                </div>
                <div>
                  <h5 className="fw-bold modal-label">Purchase Amount:</h5>
                  <p className="modal-text">{selectedPurchase.purchase_amt} ฿</p>
                </div>
                <div>
                  <h5 className="fw-bold modal-label">Purchase Date:</h5>
                  <p className="modal-text">{formatDateTime(selectedPurchase.purchase_date)}</p>
                </div>
                <div>
                  <h5 className="fw-bold modal-label">Purchase Receipt Image:</h5>
                  <img src={images[selectedPurchase.purchase_id]} alt="Purchase" className="img-fluid" />
                </div>
                {/* Buttons Row */}
                {selectedPurchase.purchase_status === 'WA' && (
                  <div className="row mt-2">
                    <div className="col">
                      <button className="btn btn-success w-100" onClick={handleApprove}>
                        Approve
                      </button>
                    </div>
                    <div className="col">
                      <button className="btn btn-danger w-100" onClick={handleReject}>
                        Reject
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}

      <Logout />
    </div>
  );
}

export default PurchaseOrder;