import React, { useEffect, useState } from 'react';
import Axios from 'axios';

// Component
import '../../../assets/css/setting.css';
import Header from 'components/Header/Header';
import Footer from 'components/Footer/Footer';
import Sidebar from 'components/Sidebar/Sidebar';
import Logout from 'components/Modal/Logout';

// Utils
import { createAlert, base64ToBlob, getFileTypeFromBase64 } from 'utils/MainUtils';

function Setting() {
    const [imagePreviewHome, setImagePreviewHome] = useState(null);
    const [imagePreviewAbout, setImagePreviewAbout] = useState(null);

    useEffect(() => {
        initialize();
    }, []);

    const initialize = async () => {
        try {
            const homeImg = await getSettingImg('home');
            const aboutImg = await getSettingImg('about');

            // ใช้ภาพดีฟอลต์ถ้าภาพที่ได้รับเป็น null หรือ undefined
            if (!homeImg) {
                const defaultHomeImgUrl = `${process.env.REACT_APP_API_URL}/uploads/setting/default/home.jpg`;
                await handleDefaultImage(defaultHomeImgUrl, 'home');
            } else {
                setImagePreviewHome(homeImg);
            }

            if (!aboutImg) {
                const defaultAboutImgUrl = `${process.env.REACT_APP_API_URL}/uploads/setting/default/about.jpg`;
                await handleDefaultImage(defaultAboutImgUrl, 'about');
            } else {
                setImagePreviewAbout(aboutImg);
            }
        } catch (error) {
            createAlert('FAILED', error.message);
            // ใช้ภาพดีฟอลต์เมื่อเกิดข้อผิดพลาด
            const defaultHomeImgUrl = `${process.env.REACT_APP_API_URL}/uploads/setting/default/home.jpg`;
            const defaultAboutImgUrl = `${process.env.REACT_APP_API_URL}/uploads/setting/default/about.jpg`;
            await handleDefaultImage(defaultHomeImgUrl, 'home');
            await handleDefaultImage(defaultAboutImgUrl, 'about');
        }
    };

    const handleDefaultImage = async (imageUrl, type) => {
        try {
            const response = await Axios.get(imageUrl, { responseType: 'arraybuffer' });
            const base64Image = `data:image/jpeg;base64,${Buffer.from(response.data, 'binary').toString('base64')}`;
            const fileType = getFileTypeFromBase64(base64Image); // แปลง URL เป็น Base64
            const blob = base64ToBlob(base64Image, fileType); // แปลง Base64 เป็น Blob
            const file = new File([blob], `default_image.${fileType.split('/')[1]}`, { type: fileType });

            await insertSettingImage(file, type);
        } catch (error) {
            createAlert('FAILED', `Failed to handle default image for ${type}: ${error.message}`);
        }
    };

    const getSettingImg = async (type) => {
        const imgResponse = await Axios.post(`${process.env.REACT_APP_API_URL}/api/get-setting-img`, {
            type: type
        }, {
            headers: { key: process.env.REACT_APP_ANALYTICS_KEY }
        });
        return imgResponse.data.image;
    };

    //INSERT IMAGE
    const insertSettingImage = async (imgFile, type) => {
        try {
            if (!imgFile) return;

            const formData = new FormData();
            formData.append('image', imgFile);

            const uploadResponse = await Axios.post(`${process.env.REACT_APP_API_URL}/api/setting/upload`, formData, {
                headers: { key: process.env.REACT_APP_ANALYTICS_KEY }
            });

            if (uploadResponse.data.status !== 'OK') {
                return createAlert(uploadResponse.data.status, uploadResponse.data.message);
            }

            const deleteResonse = await Axios.post(`${process.env.REACT_APP_API_URL}/api/delete-setting-img`, {
                type: type
            }, {
                headers: { key: process.env.REACT_APP_ANALYTICS_KEY }
            });

            if (deleteResonse.data.status !== 'OK') {
                return createAlert(deleteResonse.data.status, deleteResonse.data.message);
            };

            const imgData = {
                type: type,
                text: null,
                img_type: uploadResponse.data.contentType,
                img_file: uploadResponse.data.fileId,
                img_size: uploadResponse.data.fileSize,
                active_flag: 'Y',
                create_user: 'Admin',
                create_process: window.location.pathname
            };

            const userImgResponse = await Axios.post(`${process.env.REACT_APP_API_URL}/api/create-setting-img`, imgData, {
                headers: { key: process.env.REACT_APP_ANALYTICS_KEY }
            });

            if (userImgResponse.data.status === 'FAILED') {
                return createAlert(userImgResponse.data.status, userImgResponse.data.message);
            }
        } catch (error) {
            createAlert("FAILED", error.message);
        }
    };

    const handleUpdateOnClick = (type) => {
        if (type === 'home') {
            document.getElementById('home-img-file').click();
        } else {
            document.getElementById('about-img-file').click();
        }
    };

    const handleFileChange = (event, type) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                if (type === 'home') {
                    setImagePreviewHome(reader.result);
                } else {
                    setImagePreviewAbout(reader.result);
                }
                insertSettingImage(file, type);
            };
            reader.readAsDataURL(file);
        }
    };

    return (
        <div className="Setting">
            <div id="wrapper">
                <Sidebar />
                <div id="content-wrapper" className="d-flex flex-column">
                    <div id="content">
                        <Header />
                        <div className="d-flex align-items-center justify-content-between" style={{ padding: '2rem' }}>
                            <h1 className="h3 text-gray-800">Setting</h1>
                        </div>
                        <div className="container bootstrap snippets bootdey">
                            <div className="row">
                                <div className="main-content">
                                    <div className="tab-content profile-page">
                                        <div className="tab-pane profile active" id="profile-tab">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="user-info-left">
                                                        <h2 className="mt-3">Home</h2>
                                                        {imagePreviewHome && (
                                                            <img className="img-responsive mt-3" src={imagePreviewHome} alt="Home Picture" />
                                                        )}
                                                        <br />
                                                        <input
                                                            type="file"
                                                            id="home-img-file"
                                                            style={{ display: 'none' }}
                                                            onChange={(e) => handleFileChange(e, 'home')}
                                                        />
                                                        <button
                                                            className="btn btn-danger mt-3"
                                                            onClick={() => handleUpdateOnClick('home')}
                                                        >
                                                            Change
                                                        </button>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="user-info-left">
                                                        <h2 className="mt-3">About us</h2>
                                                        {imagePreviewAbout && (
                                                            <img className="img-responsive mt-3" src={imagePreviewAbout} alt="About Picture" />
                                                        )}
                                                        <input
                                                            type="file"
                                                            id="about-img-file"
                                                            style={{ display: 'none' }}
                                                            onChange={(e) => handleFileChange(e, 'about')}
                                                        />
                                                        <br />
                                                        <button
                                                            className="btn btn-danger mt-3"
                                                            onClick={() => handleUpdateOnClick('about')}
                                                        >
                                                            Change
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
            <Logout />
        </div>
    );
}

export default Setting;